import React from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { Collapse } from 'react-bootstrap';
import SubMenu from './subMenu';

export default function MainOptions({ collapse, openCollapse, handleCollapseClick, activeSubMenu, handleSubMenuClick }) {
  const isOpen = openCollapse === collapse.id;

  return (
    <div key={collapse.id} className='main-options'>
      <div className='link-no-underline' onClick={() => handleCollapseClick(collapse.id)}>
        <div className="icon-text-container">
          <div className={`left-content ${isOpen ? 'open-collapse' : ''}`}>
            <span className='info-menu-icon'>{collapse.icon}</span>
            <span>{collapse.text}</span>
          </div>
          {isOpen ? <MdExpandLess className="expand-icon" size={20} /> : <MdExpandMore className="expand-icon" size={20} />}
        </div>
      </div>
      <Collapse in={isOpen}>
        <ul>
          {collapse.submenus.map((submenu) => (
            <SubMenu
              key={submenu.id}
              submenu={submenu}
              activeSubMenu={activeSubMenu}
              handleSubMenuClick={handleSubMenuClick}
            />
          ))}
        </ul>
      </Collapse>
    </div>
  );
}