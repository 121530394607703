import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch, Link, Redirect, useLocation } from 'react-router-dom';
import Badge from '@mui/joy/Badge';
import styled, { keyframes, css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';
import { BsList, BsGearFill } from "react-icons/bs";
import { IoNotifications } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import { BiSolidExit } from "react-icons/bi";
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import useCollapses from './mainData';
import MainOptions from './mainOptions';
import AsyncComponent from './asyncComponent';
import { ConfirmationBox } from '../../Utils/CustomMessagingModal';
import { getDecodedToken, hasPermission } from '../../Utils/Token';
import { Permissions } from '../../Utils/Permissions';
import api from '../../../services/api'
import img from '../../../assets/img/profile-img.jpg';

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const StyledBadge = styled(Badge)(({ theme }) => css`
  & .MuiBadge-badge {
    background-color: #DC143C;
    border-radius: 50%;
    min-width: 5px !important;
    min-height: 12px !important;
    border: none !important;
    bottom: 30px;
    left: 30px;
    transform: translate(-50%, 50%);
    animation: ${blink} 1s infinite;
  }
`);

export default function AdminHome() {
    const collapses = useCollapses();
    const decodedToken = getDecodedToken();
    const history = useHistory();
    const location = useLocation();
    const [showMenu, setShowMenu] = useState(true);
    const [openCollapse, setOpenCollapse] = useState(null);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [activeConfigurations, setActiveConfigurations] = useState(false);
    const [activeNotifications, setActiveNotifications] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [userFullName, setUserFullName] = useState(null);
    const [userImage, setuserImage] = useState(null);
    const [notificationCount, setNotificationCount] = useState(0);

    //função que será executada se o usuário clicar em Confirmar quando aparecer o modal de confirmação
    const handleConfirmationExit = () => {
      localStorage.clear();
      history.push('/login');
    };

    //função que será executada se o usuário clicar em Cancelar quando aparecer o modal de confirmação
    const handleCancelExit = () => {
      setShowConfirmation(false);
    };

    //função que irá abrir o modal de confirmação da solicitação personalizado
    const handleExitClick = () => {
      setShowConfirmation(true);
    };

    const handleSubMenuClick = (id) => {
      setActiveSubMenu(id);
      setActiveConfigurations(false);
      setActiveNotifications(false);

      if (window.innerWidth < 768) {
        setShowMenu(false);
      }
    };

    const handleConfigurationsClick = () => {
      setActiveConfigurations(true);
      setActiveNotifications(false);
      setOpenCollapse(null);
      setActiveSubMenu(null);

      if (window.innerWidth < 768) {
        setShowMenu(false);
      }
    };

    const handleNotificationsClick = () => {
      setActiveNotifications(true);
      setActiveConfigurations(false);
      setNotificationCount(0);  
      setOpenCollapse(null);
      setActiveSubMenu(null);

      if (window.innerWidth < 768) {
        setShowMenu(false);
      }
      
      history.push('/dashboard/notifications')
    };

    const toggleMenu = () => {
      setShowMenu(!showMenu);
    };

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setShowMenu(false);
        }
      };

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
    const handleCollapseClick = (id) => {
      setOpenCollapse(openCollapse === id ? null : id);
    };

    const forceUpdate = useCallback(() => setNotificationCount(prev => prev + 1), []);

    useEffect(() => {
      const socketUrl = process.env.REACT_APP_API_URL + '/api/v1/ws';
      const socket = new SockJS(socketUrl);
      const client = new Client({
        webSocketFactory: () => socket,
        reconnectDelay: 5000,
        debug: (str) => {
          //console.log(new Date(), str);
        },
      });
  
      client.onConnect = () => {
        console.log('WebSocket connected');
        Notifications();

        client.subscribe('/topic/notifications', async (message) => {
          console.log("Websocket message -> " + message.body);

          if (location.pathname === '/dashboard/notifications') {
            forceUpdate();
          }

          if(message.body === 'reader') {
            setNotificationCount(0);  
          } else {
            setNotificationCount(1);  
          }
        });
      };
  
      client.onStompError = (frame) => {
        console.error('Erro na conexão ao WebSocket:', frame);
      };
  
      client.activate();
  
      return () => {
        if (client) {
          client.deactivate();
          console.log('WebSocket disconnected');
        }
      };
    }, []);

    async function Notifications() {
      try {
        const response = await api.get('/notification/count', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          }
        });
      
        setNotificationCount(response.data);  
      } catch (error) {
        console.error('Falha ao buscar dados do usuário:', error);
      }
    }
    
    useEffect(() => {
      const fetchData = async () => {
        try {

          const user = await api.get(`/user/username/${localStorage.getItem('username')}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
          });
    
          setUserFullName(user.data.fullName);
          setuserImage(user.data.image);
        } catch (error) {
            console.error('Falha ao buscar dados do usuário:', error);

            //se der bad request é pq o token expirou
            if(error.code === 'ERR_BAD_REQUEST') {
                localStorage.clear();
                history.push('/login#expired');
            }
        }
      };
    
      fetchData();
    }, []);

    return (
      <div className='main'>
        <Row>
          <Col md={showMenu ? 3 : 0} className={`col-menu-admin ${!showMenu ? 'hide-menu' : 'show-menu'}`}>
            <div className='menu-button'>
              { showMenu ? <IoCloseSharp size={25} className='btn-main' onClick={toggleMenu}/> : <BsList size={30} className='btn-toggle' onClick={toggleMenu}/> }
            </div>
            {showMenu && (
              <div className='menu scrollable-card-body'>
                <div className='main-visible'>
                  <Row>
                    <Col md={12} className='pb-3' style={{ textAlign: 'center' }}>
                        <Image className='rounded-circle' width={150} height={150} src={userImage != null ? userImage : img}/>
                    </Col>
                    <Col md={12} className='pb-0' style={{ textAlign: 'center' }}>
                        <h5>Bem vindo, {userFullName != null ? userFullName : 'Administrador'}!</h5>
                    </Col>
                    <Col md={12} className='pb-4' style={{ textAlign: 'center' }}>
                      {hasPermission(decodedToken, Permissions.ADMIN) && (
                        <Link 
                          className={`notifications-main ${activeNotifications ? 'active' : ''}`}
                          to="/dashboard/notifications"
                          onClick={handleNotificationsClick}
                        >
                          <StyledBadge
                              badgeContent={notificationCount > 0 ? ' ' : null}
                              overlap="circular"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                          >
                            <span title='Notificações'>
                              <IoNotifications 
                                className='user-icons' 
                                size={28}/>
                            </span>
                          </StyledBadge>
                        </Link>
                      )}
                      <Link 
                        className={`configurations-main ${activeConfigurations ? 'active' : 'inactive'}`}
                        to="/dashboard/configurations"
                        onClick={handleConfigurationsClick}
                      >
                        <span title='Configurações'>
                          <BsGearFill 
                            className='user-icons' 
                            size={28}/>
                        </span>
                      </Link>
                      <span title='Sair'>
                          <BiSolidExit 
                            className='user-icons' 
                            size={28} 
                            onClick={handleExitClick}
                          />
                      </span>
                      <ConfirmationBox
                        show={showConfirmation}
                        message="Deseja sair do Dashboard?"
                        onConfirm={handleConfirmationExit}
                        onCancel={handleCancelExit}
                      />
                    </Col>
                    {collapses.map((collapse) => (
                      <MainOptions
                        key={collapse.id}
                        collapse={collapse}
                        openCollapse={openCollapse}
                        handleCollapseClick={handleCollapseClick}
                        activeSubMenu={activeSubMenu}
                        handleSubMenuClick={handleSubMenuClick}
                      />
                    ))}
                  </Row>
                </div>
              </div>
            )}
          </Col>
          <Col className="col-menu-admin" md={showMenu ? 9 : 12}>
            <div className={`menu-container ${!showMenu ? '' : 'menu-container-closed'}`}>
              <div className={`${!showMenu ? 'scrollable-container' : 'scrollable-container-view'}`}>
                <Switch>
                  <Route exact path="/dashboard" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Orders/orders')}
                    />
                  )} />
                  <Route exact path="/dashboard/notifications" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../Notifications/notifications')}
                        key={notificationCount}
                        isMobile={true}
                        setShowMenu={setShowMenu}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/notification/:id" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../Notifications/notificationView')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/configurations" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Configurations/configurations')}
                    />
                  )} />
                  <Route exact path="/dashboard/paymentMethods" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../PaymentMethods/paymentMethods')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/order/status" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../Orders/orderStatus')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/customers" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Customers/customers')}
                    />
                  )} />
                  <Route exact path="/dashboard/prodCategory" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../Products/category')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/products" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../Products/products')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/orders" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Orders/orders')}
                    />
                  )} />
                  <Route exact path="/dashboard/order/:id" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Orders/orderView')}
                    />
                  )} />
                  <Route exact path="/dashboard/order" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../Orders/orderNew')}
                    />
                  )} />
                  <Route exact path="/dashboard/users" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../Users/users')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/chartOfAccounts/codes" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../ChartOfAccounts/codes')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/chartOfAccounts/entries" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../ChartOfAccounts/entries')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/dailyProduction" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../DailyProduction/dailyProduction')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/shipments" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../Shipments/shipments')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/stock" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../Stock/stock')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/chartOfAccounts/reports" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../Reports/chartOfAccounts')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/stock/reports" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../Reports/stock')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/shipments/reports" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../Reports/shipments')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route exact path="/dashboard/dailyProduction/reports" render={() => (
                    hasPermission(decodedToken, Permissions.ADMIN) ? (
                      <AsyncComponent
                        importComponent={() => import('../Reports/productions')}
                      />
                    ) : ( <Redirect to="/notfound" /> )
                  )} />
                  <Route path="*" render={() => (
                    <AsyncComponent
                      importComponent={() => import('../../../pages/NotFound')}
                    />
                  )} />
                </Switch>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
}