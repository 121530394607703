import React, { useMemo } from 'react';
import { IoStorefrontSharp, IoBagCheck } from "react-icons/io5";
import { FaMoneyBill1 } from "react-icons/fa6";
import { FaPeopleGroup, FaUser } from "react-icons/fa6";
import { GrUserWorker } from "react-icons/gr";
import { MdCategory } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { TbTruckDelivery } from "react-icons/tb";
import { getDecodedToken, hasPermission } from '../../Utils/Token';
import { Permissions } from '../../Utils/Permissions';
import { FaChartPie } from "react-icons/fa";

const useCollapses = () => {
  const decodedToken = getDecodedToken();

  return useMemo(() => {
    const collapses = [
      {
        id: 1,
        icon: <FaPeopleGroup className="info-icon" size={22} />,
        text: "Pessoas",
        submenus: [
          { id: 1, text: "Visualizar", href: "/dashboard/customers" }
        ]
      },
      {
        id: 5,
        icon: <IoBagCheck className="info-icon" size={22} />,
        text: "Pedidos",
        submenus: [
          { id: 1, text: "Visualizar", href: "/dashboard/orders" }
        ]
      }
    ];

    if (hasPermission(decodedToken, Permissions.ADMIN)) {
      collapses.push(
        {
          id: 2,
          icon: <FaMoneyBill1 className="info-icon" size={22} />,
          text: "Formas de Pagamento",
          submenus: [
            { id: 1, text: "Visualizar", href: "/dashboard/paymentMethods" }
          ]
        },
        {
          id: 3,
          icon: <MdCategory className="info-icon" size={22} />,
          text: "Status do Pedido",
          submenus: [
            { id: 1, text: "Visualizar", href: "/dashboard/order/status" }
          ]
        },
        {
          id: 4,
          icon: <IoStorefrontSharp className="info-icon" size={22} />,
          text: "Produtos",
          submenus: [
            { id: 1, text: "Categorias", href: "/dashboard/prodCategory" },
            { id: 2, text: "Produtos", href: "/dashboard/products" }
          ]
        },
        {
          id: 6,
          icon: <FaUser className="info-icon" size={22} />,
          text: "Usuários",
          submenus: [
            { id: 1, text: "Visualizar", href: "/dashboard/users" }
          ]
        },
        {
          id: 7,
          icon: <FaChartPie className="info-icon" size={22} />,
          text: "Plano de Contas",
          submenus: [
            { id: 1, text: "Códigos", href: "/dashboard/chartOfAccounts/codes" },
            { id: 2, text: "Lançamentos", href: "/dashboard/chartOfAccounts/entries" },
            { id: 3, text: "Relatórios", href: "/dashboard/chartOfAccounts/reports" }
          ]
        },
        {
          id: 8,
          icon: <GrUserWorker className="info-icon" size={22} />,
          text: "Produção Diária",
          submenus: [
            { id: 1, text: "Visualizar", href: "/dashboard/dailyProduction" },
            { id: 2, text: "Relatórios", href: "/dashboard/dailyProduction/reports" }
          ]
        },
        {
          id: 9,
          icon: <TbTruckDelivery className="info-icon" size={22} />,
          text: "Carregamentos",
          submenus: [
            { id: 1, text: "Visualizar", href: "/dashboard/shipments" },
            { id: 2, text: "Relatórios", href: "/dashboard/shipments/reports" }
          ]
        },
        {
          id: 10,
          icon: <AiFillProduct className="info-icon" size={22} />,
          text: "Estoque",
          submenus: [
            { id: 1, text: "Visualizar", href: "/dashboard/stock" },
            { id: 2, text: "Relatórios", href: "/dashboard/stock/reports" }
          ]
        },
      );
    }

    return collapses;
  }, [decodedToken]);
};

export default useCollapses;